<template>
  <v-dialog overlay-color="white" overlay-opacity="0.8" v-model="dialog">
    <template v-slot:activator="{ on, attrs }">
      <p v-bind="attrs" v-on="on">{{ userName }}</p>
    </template>
    <v-toolbar color="primary" dark class="userModalHeader">
      <div>
        {{ userName }}
      </div>
      <v-spacer></v-spacer>

      <v-btn icon @click="dialog = false">
        <v-icon>mdi-close-circle</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card>
      <profile-view-card :candidate="candidate"></profile-view-card>

      <v-divider></v-divider>
    </v-card>
  </v-dialog>
</template>

<script>
import ProfileViewCard from "./ProfileViewCard.vue";
export default {
  components: { ProfileViewCard },
  name: "UserModal",
  data() {
    return {
      dialog: false,
    };
  },
  props: {
    candidate: Object,
    userName: String,
  },
};
</script>

<style lang="scss" scoped>
.question--title {
  font-size: 18px;
}
p {
  margin-bottom: 0;
  margin-left: 10px;
  text-align: left;
  &:hover {
    color: #624cdb;
  }
}
.userModalHeader {
  .v-toolbar__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
</style>
