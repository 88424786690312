<template>
  <v-container fluid>
    <v-row v-if="lengthOfAllCandidates >= 1">
      <v-col cols="3">
        <v-card class="title--card purpleFade">
          <v-avatar
            color="indigo"
            size="36"
            class="campaign--card--counter first--counter"
          >
            <span class="white--text">{{ candidateTabs[0].total }}</span>
          </v-avatar>
          <h1 class="full-height pa-2 d-flex flex-column">
            New
          </h1>
        </v-card>
        <div>
          <campaign-card :listFitler="'Undecided'" />
        </div>
      </v-col>
      <v-col cols="3">
        <v-card class="title--card declined--card">
          <v-avatar
            color="#B8C5D3"
            size="36"
            class="campaign--card--counter first--counter"
          >
            <span class="white--text">{{ candidateTabs[1].total }}</span>
          </v-avatar>
          <h1 class="full-height pa-2 d-flex flex-column">
            Declined
          </h1>
        </v-card>
        <div>
          <campaign-card :listFitler="'declined'"></campaign-card>
        </div>
      </v-col>
      <v-col cols="3">
        <v-card class="title--card accepted--card">
          <v-avatar
            color="#B8C5D3"
            size="36"
            class="campaign--card--counter first--counter"
          >
            <span class="white--text">{{ candidateTabs[2].total }}</span>
          </v-avatar>
          <h1 class="full-height pa-2 d-flex flex-column">
            Accepted
          </h1>
        </v-card>
        <div>
          <campaign-card :listFitler="'accepted'" />
        </div>
      </v-col>
      <v-col cols="3">
        <v-card class="title--card int--card">
          <v-avatar
            color="#B8C5D3"
            size="36"
            class="campaign--card--counter first--counter"
          >
            <span class="white--text">{{ candidateTabs[3].total }}</span>
          </v-avatar>
          <h1 class="full-height pa-2 d-flex flex-column">
            Interviewed
          </h1>
        </v-card>
        <div>
          <campaign-card :listFitler="'interviewed'"></campaign-card>
        </div>
      </v-col>
    </v-row>
    <div v-else >
      <no-candidates></no-candidates>
    </div>
  </v-container>
</template>

<script>
import CampaignCard from "../components/CampaignCard.vue";
import { mapActions, mapGetters } from "vuex";
import NoCandidates from "../components/NoCandidates/NoCandidates.vue";

export default {
  name: "CampaignView",
  data: () => ({}),
  components: {
    CampaignCard,
    NoCandidates,
  },
  computed: {
    ...mapGetters(["candidateTabs", "lengthOfAllCandidates"]),
  },
  methods: {
    ...mapActions(["changeCandidateStatus"]),
  },
};
</script>

<style lang="scss" scoped>
.title--card {
  margin-bottom: 20px;
  border-radius: 10px;
  max-width: 330px;
  padding-top: 30px;
  padding-bottom: 30px;
}
.declined--card {
  border-left: 8px solid #ff7f7f;
}
.accepted--card {
  border-left: 8px solid #2dcd7a;
}
h1 {
  font-size: 24px;
  font-weight: bold;
  color: #666666;
}
.campaign--card--counter {
  position: absolute;
  top: -15px;
  right: -10px;
  overflow: visible;
  border-radius: 50% !important;
  span {
    font-size: 15px;
  }
}
.first--counter {
  border: #fff solid 3px;
}
</style>
