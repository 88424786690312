<template>
  <draggable
    class="dropzone"
    group="people"
    @start="dragStart"
    @end="dragEnd"
    :data-id="listFitler"
  >
    <div
      v-for="candidate in candidatesList"
      :key="candidate._id"
      class="dragCard"
    >
      <v-card
        class="candidate--data--card"
        :style="candidate.notified && 'background-color: transparent;'"
        :data-id="candidate._id"
      >
        <v-row align="center">
          <v-col cols="11" sm="10">
            <v-card-text
              class="full-height pa-2 d-flex flex-row candidateCard--candidateName"
            >
              <v-icon>mdi-account-circle</v-icon>
              <user-modal
                :userName="candidate.answers[0].answerText"
                :candidate="candidate"
              ></user-modal>
            </v-card-text>
          </v-col>
          <v-col cols="1" sm="2">
            <card-actions
              :notified="candidate.notified"
              :list="listFitler"
              :id="candidate._id"
              :statusChanging="false"
            ></card-actions>
          </v-col>
        </v-row>
        <v-card-text class="email-notified">
          <v-icon :style="!candidate.notified && 'opacity: 0.3;'"
            >mdi-email</v-icon
          >
          <span v-if="candidate.notified">email sent!</span>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text
          class="d-flex flex-sm-column flex-md-row flex-row rating-and-applied"
        >
          <rating
            :candidateId="candidate._id"
            :candidateRating="candidate.rating || 0"
          />
          <div>
            <p v-if="candidate.submittedAt === 1" class="text-right">
              {{ candidate.submittedAt }} Day ago
            </p>
            <p v-if="candidate.submittedAt === 0" class="text-right">Today</p>
            <p v-if="candidate.submittedAt > 1" class="text-right">
              {{ candidate.submittedAt }} Days ago
            </p>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </draggable>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Rating from "./Rating.vue";
import CardActions from "./CardActions.vue";
import UserModal from "./UserModal.vue";
import draggable from "vuedraggable";

export default {
  name: "CampaignCard",
  data: () => ({
    optionsMarkMail: "Mark e-mail sent",
    optionsRemoveMail: "Unmark e-mail sent",
    draggedUserId: null,
    toDragzone: null,
  }),
  props: {
    listFitler: String,
  },
  components: { Rating, CardActions, UserModal, draggable },
  computed: {
    ...mapGetters(["allCandidates", "defaultCampaign"]),
    candidatesList() {
      switch (this.listFitler) {
        case "Undecided":
          return this.allCandidates
            .filter((candidate) => candidate.status === "Undecided")
            .sort(function (a, b) {
              return new Date(a.submittedAt) - new Date(b.submittedAt);
            })
            .sort((x) => (x.notified ? 1 : -1));
        case "declined":
          return this.allCandidates
            .filter((candidate) => candidate.status === "declined")
            .sort(function (a, b) {
              return new Date(a.submittedAt) - new Date(b.submittedAt);
            })
            .sort((x) => (x.notified ? 1 : -1));
        case "accepted":
          return this.allCandidates
            .filter((candidate) => candidate.status === "accepted")
            .sort(function (a, b) {
              return new Date(a.submittedAt) - new Date(b.submittedAt);
            })
            .sort((x) => (x.notified ? 1 : -1));
        case "interviewed":
          return this.allCandidates
            .filter((candidate) => candidate.status === "interviewed")
            .sort(function (a, b) {
              return new Date(a.submittedAt) - new Date(b.submittedAt);
            })
            .sort((x) => (x.notified ? 1 : -1));
        default:
          return this.allCandidates;
      }
    },
  },
  methods: {
    ...mapActions([
      "changeCandidateStatus",
      "deleteCandidate",
      "fetchCandidates",
    ]),
    dragStart(evt) {
      document
        .querySelectorAll(".dropzone")
        .forEach(
          (v) =>
            (v.style.cssText += `background-color:rgba(211, 219, 228, 0.7);height:${100}vh;`)
        );
      const id = evt.originalEvent.target
        .querySelector(".candidate--data--card")
        .getAttribute("data-id");

      this.draggedUserId = id;
    },
    dragEnd(e) {
      const to = e.to.getAttribute("data-id");
      this.changeCandidateStatus({ id: this.draggedUserId, filter: to });
      document
        .querySelectorAll(".dropzone")
        .forEach(
          (v) =>
            (v.style.cssText += "background-color:transparent;height:auto;")
        );
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card {
  background-color: #fff;
}
.candidateCard--candidateName {
  font-weight: bold;
  color: #666666;
  p {
    margin-bottom: 0;
    margin-left: 10px;
  }
}
.dragCard {
  border-radius: 10px;
}
.candidate--data--card {
  max-width: 330px;
  cursor: pointer;
  margin: 10px 0;
  padding: 10px;
  border-radius: 10px !important;
}
.dropzone {
  min-height: 500px;
  max-width: 330px;
  border-radius: 10px;
}
.email-notified {
  text-align: left;
  padding: 0 0 10px 10px;
  span {
    margin-left: 8px;
  }
}
.rating-and-applied {
  padding: 0 10px;
  justify-content: space-between;
  p {
    margin-top: 8px;
    font-size: 14px;
    color: #666666;
  }
}
</style>
